import { Component, Output, EventEmitter, OnDestroy, Input, PLATFORM_ID, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { SimpleModalService } from 'ngx-simple-modal';
import { UserService } from '../../services/user.service';
import { UserIdleService } from '../../services/user-idle.service';
import { CartService } from '../../../cart/services/cart.service';
import { environment } from '../../../../environments/environment';
import { SeoConfig } from '../../../core/model/seo-config.model';
import { SeoService } from '../../../core/services/seo.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { StorageService } from '../../../core/services/local-storage.service';

declare let FullStory;

@Component({
  selector: 'hos-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnDestroy {
  @Output() loginHandler: EventEmitter<any> = new EventEmitter<any>();

  @Input() isRegisterSucess: boolean;

  loadForgotPassword: Boolean = false;

  captchaResponse: String = '';

  isValidCaptcha: Boolean = false;

  message: string;

  usernameDesk: string;

  passwordDesk: string;

  usernameMob: string;

  passwordMob: string;

  loggingIn: Boolean = true;

  isRememberMe: Boolean = false;

  isClicked: Boolean = false;

  email_regex: String;

  errorStatus: any;

  loginError: String = '';

  result: any;

  errorMessage: String = '';

  successMessage: String = '';

  title: string;

  email: string;

  showSubmit: Boolean = true;

  cartSubscription: any;

  seoConfig: SeoConfig = {
    title: 'Login Service Line Warranties | American Water Resources',
    description: `American Water Resources offers affordable home protection programs
    that help protect against unexpected repair costs for service line emergencies.`,
    keywords: `service line warranties, water line protection, sewer line protection,
    in home plumbing emergencies, electric line protection, power surge protection, heating system repair, cooling system repair`,
    image: '',
    slug: '',
    sitename: '',
    type: '',
    summary: '',
    url: '',
  };

  userIdleSubscribe: any;

  userIdleSubscribePing: any;

  userIdleSubscribeTimeout: any;

  isBrowser: any;

  showLoader = false;

  redirect_link: string;

  private storage: Storage;

  constructor(
    private userService: UserService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private cartService: CartService,
    private seoService: SeoService,
    private sessionStorageService: SessionStorageService,
    private userIdle: UserIdleService,
    private globalVariables: GlobalVariables,
    private simpleModalService: SimpleModalService,
    private storageService: StorageService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.email_regex = environment.email_regex;
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (isPlatformBrowser(this.platformId)) {
      this.storage = this.storageService.get();
    }
    // this.userService.signOut();

    if (!['null', 'undefined', undefined, null, ''].includes(this.sessionStorageService.getItem('authHeader'))) {
      this.router.navigate(['/user/myaccount'], {
        queryParams: {
          isLogin: true,
        },
      });
    }

    this.userService.getAzureB2cRedirectUrl().subscribe((data)=>{
      this.redirect_link = data.body.link
    });
  }

  verifyCallback(data: String) {
    this.captchaResponse = data;
  }

  onCaptchaSubmit() {
    if (this.captchaResponse) {
      this.userService.verifyCaptcha(this.captchaResponse).subscribe(
        () => {
          this.title = 'Forgot your password?';
          this.isValidCaptcha = true;
        },
        () => {
          this.title = 'Forgot your password?';
          this.isValidCaptcha = true;
        },
      );
    }
  }

  forgotPassword() {
    this.result = false;
    //this.loadForgotPassword = true;
    window.location.href = this.redirect_link;
  }

  hideForgotPassword() {
    this.loadForgotPassword = false;
  }

  goToRegister() {
    // if (this.router.url.indexOf('/user/register') > -1) {
    //   window.location.reload();
    // } else {
    //   this.router.navigate(['/user/register'], { queryParams: { referer: this.router.url } });
    // }
    const { referer } = this.activatedRoute.snapshot.queryParams;
    if (referer) {
      this.globalVariables.routeReferer = decodeURIComponent(referer);
      this.router.navigate(['/user/register'], {
        queryParams: {
          referer: this.globalVariables.routeReferer,
        },
      });
    } else {
      this.router.navigate(['/user/register']);
    }
  }

  /**
   * Form submit handler
   *
   * @param {NgForm} signInForm
   *
   * @memberof LoginComponent
   */
  onSubmit(signInForm: NgForm, formFrom: number) {
    this.loginError = '';
    this.loggingIn = false;
    this.isClicked = true;
    if (signInForm.valid) {
      let username;
      let password;
      if (formFrom === 1) {
        username = signInForm.value.usernameDesk;
        password = signInForm.value.passwordDesk;
      } else {
        username = signInForm.value.usernameMob;
        password = signInForm.value.passwordMob;
      }
      this.userService.signIn(username, password).subscribe(
        async data => {
          this.isClicked = false;
          const userData = data.body;
          if (data.status === 200) {
            this.sessionStorageService.setItem('authHeader', userData.authHeader);
            this.sessionStorageService.setItem('refreshToken', userData.refreshToken);
            this.sessionStorageService.setItem('userphoneNumber', userData.user.phoneNumber);
            if (this.isRememberMe) {
              this.sessionStorageService.setItem('authHeader', userData.authHeader);
              this.sessionStorageService.setItem('refreshToken', userData.refreshToken);
            }
            const userDetails = this.userService.getSignedInUser();
            userDetails.user.email_verified = userData.user.email_verified;
            this.sessionStorageService.setItem('loginUserDetails', JSON.stringify(userDetails));
            this.cartService.getCustomerCartToLocal(userDetails.user._id, '', status => {
              this.errorStatus = status;
              this.loggingIn = true;
              this.result = {
                confirm: true,
              };
              this.userService.setLoggin(userData.authHeader);
              const cartGet = this.cartService.get();
              this.cartSubscription = cartGet.subscribe(cart => {
                this.globalVariables.cartAmount.emit(cart);
              });
            });
            // Condition to delete locally stored cart data once logged in
            // if (isPlatformBrowser(this.platformId)) {
            //     this.storage.removeItem('cartCheckout');
            // }
            this.loginHandler.emit(userData.authHeader);
            // update primary email if not matched
            await this.updatePrimaryEmail(userDetails.user._id, userDetails.user.email);
            const { referer } = this.activatedRoute.snapshot.queryParams;
            if (referer) {
              this.globalVariables.routeReferer = decodeURIComponent(referer);
              const isFragment: any = this.globalVariables.routeReferer.split('?');
              const ignoreArry = environment.ignoreQueryParamsArry;
              if (isFragment && isFragment.length > 1) {
                const querySplitand = isFragment[1].split('&');
                let splitObj = querySplitand.filter(item => {
                  const splitItem = item.split('=');
                  if (splitItem.length > 0 && ignoreArry.indexOf(splitItem[0]) < 0) {
                    return item;
                  }
                  return false;
                });
                splitObj = `?${splitObj.join('&')}`;
                this.globalVariables.routeReferer = isFragment[0] + splitObj;
              }
              this.router.navigateByUrl(decodeURIComponent(this.globalVariables.routeReferer));
            } else if (this.sessionStorageService.getItem('directPayment') !== '1') {
              this.router.navigate(['/user/myaccount'], {
                queryParams: {
                  isLogin: true,
                },
              });
            } else {
              this.sessionStorageService.deleteItem('directPayment');
            }

            this.userIdle.resetTimer();
            this.userIdle.startWatching();

            // Start watching when user idle is starting.
            this.userIdleSubscribe = this.userIdle.onTimerStart().subscribe();
            this.userIdleSubscribePing = this.userIdle.ping$.subscribe();

            // Start watch when time is up.
            this.userIdleSubscribeTimeout = this.userIdle.onTimeout().subscribe(() => {
              this.userIdleSubscribe.unsubscribe();
              this.userIdleSubscribePing.unsubscribe();
              this.userIdle.stopWatching();
              this.userService.signOut();
              this.userIdleSubscribeTimeout.unsubscribe();
              this.router.navigate(['/']);
            });
          } else if (userData.error) {
            this.loggingIn = true;
            this.loginError = userData.message;
          }
        },
        error => {
          this.loggingIn = true;
          this.isClicked = false;
          if (error && error.error && error.error.message) {
            const errorMessage = error.error.message;
            // Check if the error message contains specific Azure B2C error strings
            if (errorMessage.includes('The email is not set in Azure B2C')) {
                this.loginError = 'Invalid email. Please try again';
            } else if (errorMessage.includes('Invalid credentials as per Azure B2C')) {
                this.loginError = 'Invalid password. Please reset your password';
                // Redirect to azure reset password page
                window.location.href = this.redirect_link;
            } else {
                // Set loginError to the original error message if no specific match
                this.loginError = errorMessage;
            }
          } else {
            this.loginError = `We are experiencing technical difficulties. Please try again.
If you require further assistance please call customer service.`;
          }
        },
      );
    } else {
      this.loggingIn = true;
    }
  }

  async updatePrimaryEmail(userId: string, email: string) {
    const updatePrimaryEmail = await this.updatePrimaryEmailApi(userId);
    const userProfile = updatePrimaryEmail.body;
    this.globalVariables.userProfileDetails = userProfile;
    if (userProfile) {
      const selectedAddress = userProfile.address[0];
      const userDetails = this.userService.getSignedInUser();
      if (typeof FullStory === 'function') {
        FullStory.identify(selectedAddress.customerNo, {
          displayName: selectedAddress.customerNo,
          name: `${selectedAddress.firstName} ${selectedAddress.lastName}`,
          email: userDetails.user.email,
        });
      }
    }
    this.sessionStorageService.setItem('userProfileDetails', JSON.stringify(userProfile));
    this.globalVariables.userLoggedIn.emit(true);
    if (updatePrimaryEmail.status === 200) {
      if (email !== userProfile.email) {
        let emailpayload: any = {};
        emailpayload = {
          email,
        };
        this.update(userId, emailpayload, () => { });
      }
    }
  }

  public updatePrimaryEmailApi(userId: any): Promise<any> {
    return this.userService.getUserProfile(userId).toPromise();
  }

  update(userid, payload, done) {
    this.userService.updateCustomerInfo(userid, payload).subscribe(
      res => {
        if (res.status === 200) {
          done(true);
        } else {
          done(false);
        }
      },
      () => {
        this.showLoader = false;
        done(false);
      },
    );
  }

  keyPresshandler() {
    this.isClicked = false;
  }

  ngOnDestroy() {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
    if (this.isBrowser) {
      let urlOperation: any = window.location.pathname.split('/');
      const micrositeNames = this.sessionStorageService.getItem('micrositeName')
        ? JSON.parse(this.sessionStorageService.getItem('micrositeName'))
        : environment.micrositeNames;
      // urlOperation = urlOperation.filter(item => !environment.micrositeNames.includes(item) && item !== "");
      urlOperation = urlOperation.filter(item => !micrositeNames.includes(item) && item !== '');
      if (urlOperation.length > 0 && ['products', 'product'].includes(urlOperation[0])) {
        /* add logic for matching route array check */
      } else {
        this.globalVariables.nonLoggedInProductDetails = {};
        this.globalVariables.nonLoggedInProductListingDetails = {};
        this.globalVariables.nonLoggedInRenewedOrderIds = [];
      }
    }
  }
}
